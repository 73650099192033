<template>
  <div id="create">
    <!-- 报错 -->
    <van-empty
      :description="errorText"
      v-if="errorText && errorText.length != 0"
      image="error"
    >
      <van-button round class="bottom-button " @click="$router.go(-1)"
        >返回</van-button
      >
      <van-button
        round
        type="primary"
        class="bottom-button"
        @click="$router.push({ name: 'HomeIndex' })"
        >回到首页</van-button
      >
    </van-empty>

    <template v-else>
      <oLoading v-if="loadingBoo" :num="loadingTime"></oLoading>

      <swiper
        ref="mySwiper"
        :options="{
          ...leftTitle,
        }"
        class="swiper-container-left"
        style="z-index: 99;"
      >
        <swiper-slide
          v-for="(item, index) in list"
          style="width:100%"
          :key="index"
        >
          <!-- <div class="wrapper_fixed"></div> -->
          <div v-if="finalShow" class="aa">
            <v-touch class="solid_able" v-on:pressup="gtouchstart"></v-touch>
            <oSolid :item="item.image_url"></oSolid>
          </div>

          <div class="aa" v-if="!SolidShow">
            <div class="finalImg">
              <img class="show" :src="UserArrCreareImage[index]" />
            </div>
          </div>
        </swiper-slide>

        <!-- <div class="swiper-pagination" slot="pagination"></div> -->
      </swiper>

      <div class="fixed2">
        <div class="f2">
          <div class="edit_btn" @click="actionShow = true">
            <svg-icon iconClass="create_edit"></svg-icon>编辑店家信息
          </div>
        </div>
      </div>

      <div class="fixed_jt">
        <svg-icon iconClass="create_left"></svg-icon>
        <svg-icon iconClass="create_right"></svg-icon>
      </div>

      <oSolid2
        v-if="SolidShow"
        :item="item.image_url"
        class="mainImg2"
        ref="mainImg3"
        v-for="(item, index) in list"
        :key="index"
        :form="form"
      ></oSolid2>

      <van-popup
        v-model="actionShow"
        round
        position="bottom"
        closeable
        class="input_popup"
      >
        <van-field
          v-model="form.name"
          label="工作室/公司/姓名"
          placeholder="请输入工作室/公司/姓名"
          maxlength="13"
        />
        <van-field
          v-model="form.phone"
          label="手机号码"
          placeholder="请输入手机号码"
          maxlength="11"
        />
        <van-field
          v-model="form.address"
          label="地址"
          placeholder="请输入地址"
          maxlength="26"
        />
        <div class="input_upload">
          <p class="input_text">个人二维码</p>
          <div class="input_solid">
            <img :src="form.businessCardImage" class="image" />
            <van-uploader
              class="uploader"
              multiple
              :max-count="1"
              :after-read="afterRead"
              v-model="fileList"
            >
            </van-uploader>
          </div>
        </div>
        <div class="input_save">
          <van-button type="primary" round @click="saveUserForm()"
            >保存资料</van-button
          >
        </div>
      </van-popup>
    </template>
  </div>
</template>

<script>
import html2canvas from "html2canvas";
import oSolid from "./components/solid.vue";
import oSolid2 from "./components/solid2.vue";
import { Popup, Field, Uploader, Button, Toast, Empty } from "vant";
import { Swiper, SwiperSlide, directive } from "vue-awesome-swiper";
import oLoading from "./components/loading.vue";
import { get, post } from "@get/http";
import { upload, getOss } from "@get/upload";
import "swiper/css/swiper.css";
export default {
  components: {
    oLoading,
    Swiper,
    SwiperSlide,
    oSolid,
    oSolid2,
    vanPopup: Popup,
    vanField: Field,
    vanUploader: Uploader,
    vanButton: Button,
    vanEmpty: Empty,
  },
  data() {
    return {
      timeOutEvent: 0, // 长按事件定时器
      localStorageKey: "SHOP_ONlYPRODUCT_FORM",
      loadingBoo: false,
      loadingTime: 0,
      loadingTImer: null,
      errorText: "",
      form: {
        name: "",
        phone: "",
        address: "",
        businessCardImage:
          "https://image.shop.19jiang.cn/home/store/goods/2/alioss_2_2021111310382636845.png",
      },
      fileList: [],
      actionShow: false,
      finalShow: true,
      SolidShow: false,
      UserArrCreareImage: [],
      list: [],
      leftTitle: {
        // loop: true,
        // speed: 500,
        // autoplay: true,
        // slidesPerView: 3,
        slidesPerView: "auto",
        centeredSlides: true,
      },
      isShow: false,
      htmlUrl: "",
    };
  },
  computed: {
    swiper() {
      return this.$refs.mySwiper.$swiper;
    },
  },
  async created() {
    await getOss();
  },
  async mounted() {
    let data = await post(
      "/lw.UserCardPoster/getList",
      {},
      { result: true, toast: false, resultKey: "all" }
    );

    if (data.code == 10000) {
      this.list = data.result.list;

      if (this.list?.length != 0) {
        // return false;
        // return false;
        this.getDataInit();
      } else {
        this.loadingBoo = false;
      }
    } else {
      this.errorText = data.message;
    }
  },
  methods: {
    gtouchstart() {
      alert("需要上传资料生成专属图片后才可以保存哦~");
    },
    async getDataInit() {
      let lo = localStorage[`${this.localStorageKey}`];
      if (lo && lo.length != 0) {
        lo = JSON.parse(lo);
        this.form = lo;
        this.SolidShow = true;
        this.UserArrCreareImage = [];

        // Toast.loading({
        //   message: "生成中...",
        //   forbidClick: true,
        //   overlay: true,
        //   duration: 0,
        //   getContainer: "#app",
        // });

        this.loadingBoo = true;
        setTimeout(async () => {
          let domArr = document.querySelectorAll(".mainImg2");

          for (let index = 0; index < domArr.length; index++) {
            // console.log(this.$refs.mainImg3[index].$el, 123123);
            let url = await this.toImage(this.$refs.mainImg3[index].$el);
            this.loadingTime = (100 / domArr.length) * (index + 1);
            this.UserArrCreareImage.push(url);
          }
          this.finalShow = false;
          this.SolidShow = false;

          if (this.list.length == this.UserArrCreareImage.length) {
            // Toast.clear();
            this.loadingBoo = false;
            this.actionShow = false;
            Toast({
              message: "长按海报保存到相册",
              duration: 3000,
            });
            this.loadingTime = 0;
          }
        }, 1000);
      }
    },

    async saveUserForm() {
      if (!this.form.name) {
        Toast("请输入主体");
        return false;
      }
      if (!this.form.phone) {
        Toast("请输入手机号码");
        return false;
      }
      if (!this.form.address) {
        Toast("请输入地址");
        return false;
      }
      if (
        this.form.businessCardImage ==
        "https://image.shop.19jiang.cn/home/store/goods/2/alioss_2_2021111310382636845.png"
      ) {
        Toast("请上传个人二维码名片");
        return false;
      }
      localStorage[`${this.localStorageKey}`] = JSON.stringify(this.form);
      await post("/lw.UserCardPoster/setUserInfo", {
        data: {
          ...this.form,
        },
      });
      // this.getDataInit();
      window.location.reload();
    },
    async afterRead(file) {
      Toast.loading({
        message: "上传中...",
        forbidClick: true,
      });
      let data = await upload(file);
      this.form.businessCardImage = `https://image.shop.19jiang.cn${data.name}`;
      Toast("上传成功");
    },
    async toImage(dom) {
      try {
        return new Promise((ro, rj) => {
          (window.html2canvas || html2canvas)(dom, {
            async: true,
            backgroundColor: null,
            useCORS: true,
            scale: window.devicePixelRatio || 1,
            // scrollY: 0,
            // scrollX: 0,
          })
            .then((canvas) => {
              let url = canvas.toDataURL("image/png");
              ro(url);
            })
            .catch((error) => {});
        });
      } catch (error) {
        console.log(error, "报错");
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import "~@css/mixin.scss";

.aa {
  width: rem(550);
  // height: rem(890);
  margin: 0 auto;
  position: relative;
  .solid_able {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 9999;
  }
}

.swiper-container-left {
  padding-top: rem(40);
  position: relative;
  // background: #fff;
  // overflow: hidden;
  width: rem(750);
  // height: rem(890);
  .swiper-slide {
    // height: auto;
    width: 78% !important;

    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    // height: rem(890);
    margin: 0 auto;
    .finalImg {
      padding: 0 !important;
      .show {
        width: 100%;
      }
    }
  }
}

.curr {
  color: #666666;
  font-size: rem(24);
  text-align: center;
  margin-top: rem(20);
}

#pageDiv {
  width: 100%;
}

.mainImg2 {
  position: absolute;
  z-index: -1;
  top: 0;
  width: auto !important;
  left: 0;
}

#create {
  background: #e5e5e5;
  width: 100%;
  height: 100%;
  padding-bottom: rem(200);
}

.input_popup {
  padding: rem(30) 0;
}
.input_solid {
  position: relative;
  .image {
    width: rem(160);
    display: block;

    height: rem(160);
  }
  .uploader {
    position: absolute;
    top: 0;
    left: 0;
    opacity: 0;
  }
}
.input_upload {
  width: 6.2 em;
  display: flex;
  box-sizing: border-box;
  width: 100%;
  padding: 10px 16px;
  overflow: hidden;
  color: #323233;
  font-size: 14px;
  // font-size: rem(22);
  background-color: #fff;
  align-items: center;
}
.input_text {
  color: #646566;
  margin-right: rem(40);
}
.input_save {
  width: 100%;

  ::v-deep {
    .van-button {
      display: block;
      width: rem(400);
      margin: rem(10) auto rem(20);
      // margin: 0 auto;
    }
  }
}
.wrapper_fixed {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.5);
  z-index: -1;
}

.fixed {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  height: rem(98);
  background: #fff;
  display: flex;
  z-index: 99;
  .f1,
  .f2 {
    display: flex;
    align-items: center;
    justify-content: center;
    flex: 1;
    height: rem(98);
    svg {
      margin-right: rem(14);
    }
  }
  .f1 {
    svg {
      width: rem(22);
      height: rem(26);
    }
  }

  .f2 {
    svg {
      width: rem(30);
      height: rem(30);
    }
  }
  .edit_btn {
    color: #222222;
    font-size: rem(26);
  }
  .tip {
    color: #989898;
    font-size: rem(26);
  }
}

.fixed2 {
  position: fixed;
  bottom: rem(30);
  left: 0;
  right: 0;
  margin: 0 auto;
  width: rem(334);
  height: rem(84);
  z-index: 99;
  .f2 {
    border-radius: rem(16);
    display: flex;
    align-items: center;
    background: #ff4848;
    text-align: center;
    svg {
      width: rem(30);
      height: rem(30);
      margin-right: rem(14);
    }
    .edit_btn {
      display: flex;
      color: #ffffff;
      font-size: rem(30);
      width: rem(334);
      height: rem(84);
      align-items: center;
      justify-content: center;
    }
  }
}

.fixed_jt {
  svg {
    width: rem(44);
    height: rem(44);
    position: fixed;
    z-index: 99;
    top: rem(517);
    &:nth-of-type(1) {
      left: rem(32);
    }
    &:nth-of-type(2) {
      right: rem(32);
    }
  }
}

.bottom-button {
  display: block;
  margin: 0 auto;
  margin-bottom: rem(30);
  width: rem(300);
}
</style>

<style lang="scss">
html,
body,
#app {
  width: 100%;
  height: 100%;
}
</style>
