<template>
  <div class="mainImg">
    <img class="coverImage" :src="item" />
    <div class="info">
      <ul class="data">
        <li>
          <img src="@image/h5/create_home.png" />
          <p>{{ form.name }}</p>
        </li>

        <li>
          <img src="@image/h5/create_phone.png" />
          <p>{{ form.phone }}</p>
        </li>

        <li>
          <img src="@image/h5/create_address.png" />
          <!-- <p>{{ TextChangeLine(form.address, 1) }}</p> -->
          <p>{{ TextChangeLine(form.address, 1) }}</p>
        </li>
        <li>
          <img src="@image/h5/create_address.png" style="opacity: 0" />
          <p>{{ TextChangeLine(form.address, 2) }}</p>
          <!-- <p>{{ TextChangeLine(form.address, 2) }}</p> -->
        </li>
      </ul>
      <img :src="form.businessCardImage" />
    </div>
  </div>
</template>

<script>
export default {
  props: {
    item: {},
    form: {},
  },
  methods: {
    TextChangeLine(item, mode) {
      if (mode == 1) {
        console.log(item.substring(0, 13));
        return item.substring(0, 13);
      } else {
        console.log(item.substring(0, 13));
        return item.substring(13, item.length);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import "~@css/mixin.scss";
$bs: 2;
.mainImg,
.finalImg {
  display: block;
  .show {
    width: 100%;
  }
  box-shadow: 0px rem(2 * $bs) rem($bs * 16) rgba(0, 0, 0, 0.05);
  background: #fff;
  width: rem(550 * $bs);

  // height: 100%;
  // height: rem(890 * $bs);
  // margin: 0 auto;
  // padding: rem(20 * $bs) rem(20 * $bs) 0;

  box-sizing: border-box;
  .coverImage {
    position: relative;
    display: block;
    // width: rem(506 * $bs);
    width: 100%;
    // height: rem(710 * $bs);
    margin: 0 auto;
    box-sizing: border-box;
    // padding-bottom: rem(22 * $bs);
    border-bottom: rem(1 * $bs) solid #eeeeee;
  }
  .info {
    background: #fff;
    display: flex;
    width: rem(506 * $bs);
    // height: rem(136 * $bs);
    margin: 0 auto;
    // border-top: rem(1 * $bs) solid #eeeeee;
    align-items: center;
    padding: rem(20 * $bs);
    box-sizing: border-box;
    > img {
      display: block;
      width: rem(92 * $bs);
      height: rem(92 * $bs);
      margin-right: rem(8 * $bs);
    }
    .data {
      flex: 1;
      li {
        display: flex;
        align-items: center;
        &:last-of-type {
          margin-top: rem(-8 * $bs);
        }
        p {
          width: rem(290 * $bs);
          font-size: rem(21 * $bs);
          display: block;
        }
        img {
          width: rem(22 * $bs);
          // height: rem(22 * $bs);
          margin-right: rem(6 * $bs);
        }
      }
    }
  }
}

.curr {
  color: #666666;
  font-size: rem(24);
  text-align: center;
  margin-top: rem(20);
}
.edit_btn {
  width: rem(344);
  height: rem(84);
  background: #ff4848;
  border-radius: rem(16);
  margin: 0 auto;
  font-size: rem(30);
  color: #ffffff;
  text-align: center;
  line-height: rem(84);
  margin-top: rem(43);
}

.tip {
  font-size: rem(28);
  text-align: center;
  color: #989898;
  padding: rem(24) 0 rem(65);
}
</style>
