<template>
  <div class="mainImg">
    <img class="coverImage" :src="item" />
    <div class="info">
      <ul class="data">
        <li>
          <img src="@image/h5/create_home.png" />
          <p>十九匠无限工作室</p>
        </li>
        <li>
          <img src="@image/h5/create_phone.png" />
          <p>19999999999</p>
        </li>

        <li>
          <img src="@image/h5/create_address.png" />
          <p>广州市番禺区海乐荟9座1楼</p>
        </li>
      </ul>

      <img
        src="https://image.shop.19jiang.cn/home/store/goods/2/alioss_2_2021111215250242332.jpg"
      />
    </div>
  </div>
</template>

<script>
export default {
  props: {
    item: {},
  },
};
</script>

<style lang="scss" scoped>
@import "~@css/mixin.scss";
$bs: 1;
.mainImg,
.finalImg {
  display: block;
  .show {
    width: 100%;
  }
  box-shadow: 0px rem(2 * $bs) rem($bs * 16) rgba(0, 0, 0, 0.05);
  background: #fff;
  width: rem(550 * $bs);
  // height: 100%;
  // height: rem(890 * $bs);
  // margin: 0 auto;
  // padding: rem(20) rem(20) 0;

  box-sizing: border-box;
  box-sizing: border-box;
  .coverImage {
    position: relative;
    display: block;
    // width: rem(506 * $bs);
    width: 100%;
    // height: rem(710 * $bs);
    margin: 0 auto;
    box-sizing: border-box;
    // padding-bottom: rem(22);
    border-bottom: rem(1 * $bs) solid #eeeeee;
  }
  .info {
    background: #fff;
    display: flex;
    width: rem(506 * $bs);
    // height: rem(136 * $bs);
    margin: 0 auto;
    // border-top: rem(1 * $bs) solid #eeeeee;
    align-items: center;
    padding: rem(20);
    // box-sizing: border-box;
    > img {
      display: block;
      width: rem(92 * $bs);
      height: rem(92 * $bs);
    }
    .data {
      flex: 1;
      li {
        display: flex;
        align-items: center;

        p {
          @include ell;
          // width: rem(560);
          font-size: rem(21 * $bs);
        }
        img {
          width: rem(22 * $bs);
          // height: rem(22 * $bs);
          margin-right: rem(6);
        }
      }
    }
  }
}

.curr {
  color: #666666;
  font-size: rem(24);
  text-align: center;
  margin-top: rem(20);
}
.edit_btn {
  width: rem(344);
  height: rem(84);
  background: #ff4848;
  border-radius: rem(16);
  margin: 0 auto;
  font-size: rem(30);
  color: #ffffff;
  text-align: center;
  line-height: rem(84);
  margin-top: rem(43);
}
.tip {
  font-size: rem(28);
  text-align: center;
  color: #989898;
  padding: rem(24) 0 rem(65);
}
</style>
